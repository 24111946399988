.appContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

.appContent {
    flex: 1 0 auto;
}

.header {
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2%;
}

.subheader {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2%;
}

.subtext {
    font-weight: 500;
    /* color: rgba(0, 0, 0, 0.4); */
    color: black;
    margin-bottom: 2%;
}