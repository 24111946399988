.issueProject {
    margin-bottom: 2%;
}

.issueLogo {
    height: 50px;
    margin-right: 0.5%;
}
.issueNumber {
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 0.5%;
    margin-right: 1%;
    padding-right: 1%;
    padding-left: 1%;
    display: inline-block;
    background-color: #CBBAED;
    border-radius: 50%;
}
.issueName {
    font-size: medium;
}