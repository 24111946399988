.dx-g-bs4-paging-panel nav:first-of-type {
    float: right;
}

.dx-g-bs4-toolbar input {
    float: right;
}

.pointer {
    cursor: pointer !important;
}