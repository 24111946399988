.about-container {
    margin-bottom: 2%;
}

.about-photo-collage {
    width: 80%;
    max-width: 80%;
}

.about-subheader {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 2%;
}

.card-link {
    text-decoration: none;
}

.card-text {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
}

.card-image {
    align-self: center;
    width: 50%;
}

.grow { 
    transition: all .2s ease-in-out; 
}
    
.grow:hover { 
    transform: scale(1.1); 
}

.about-left-spacer {
    margin-left: 6rem !important;
}

.about-right-spacer {
    margin-right: 6rem !important;
}

@media screen and (max-width:1000px) {  
    .about-left-spacer {
        margin-left: 1rem !important;
        justify-content: left !important;
        margin-bottom: 1rem;
    }

    .photo-collage-col {
        display: none !important;
    }
}