.investment-container {
    margin-left: 3%;
    margin-right: 2%;
}

.banner {
    background-repeat: 'no-repeat';
    height: 200px;
    background-size: contain;
    margin: 0 !important;
}