.mapPopup {
    width: 300px !important;
    background-color: rgba(255,255,255, 0.9);
}
.popupMetric {
    font-size: large;
    font-weight: bold;
    padding-bottom: 0px;
    margin-bottom: -5px;
}
.popupMetricLabel {
    font-size: small;
    padding-top: 0px;
    margin-top: 0px;
}