.debit-navbar {
    background-color: black;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 600;
    margin-bottom: 1.5%;
    padding-top: 0;
    padding-bottom: 0;
}


.nav-logo {
    height: 1.5em;
    width: 1.5em;
}

.navbar-light .navbar-brand {
    font-size: 1.5em;
    color: #FFFFFF;
}

.navbar-light .navbar-brand:hover {
    color: #666666;
}

.navbar-light .navbar-nav .nav-link {
    color: #FFFFFF;
}

.navbar-light .navbar-nav .nav-link:active {
    color: #FFFFFF;
    opacity: 1;
}

.navbar-light .navbar-nav .nav-link:focus {
    color: #FFFFFF;
    opacity: 1;
}

.navbar-light .navbar-nav .nav-link:visited {
    color: #FFFFFF;
    opacity: 1;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #666666;
}
