.debitFooter {
    background-color: #015151;
    color: #FFFFFF;
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 2%;
    flex-shrink: 0;
}

.copyright {
    background-color: #C9E6E0;
    color: black;
    font-weight: bold;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.paddedColumn {
    margin-left: 5%;
}

.social-media-icon {
    margin-right: 5%;
}

.footerSectionTitle {
    text-transform: uppercase; 
}

.debitFooter a:hover {
    color: #666666 !important;
}

.debitFooter a,
.debitFooter a:active, 
.debitFooter a:focus,
.debitFooter a:visited {
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    text-decoration: none;
}

.dsiLogo {
    padding: 30px;
    background-color: white;
    border-radius: 20px;
    width: 300px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.eleventhHourLogo {
    border-radius: 20px;
    width: 300px;
}

.idiLogo {
    background-color: white;
    border-radius: 20px;
    width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.acLogo {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    width: 300px;
}


/** Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .logo {
        margin-bottom: 20px;
    }
}

/** Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .logo {
        margin-bottom: 20px;
    }
}

/** Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .logo {
        margin-bottom: 20px;
    }
}

/** X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .logo {
        margin-bottom: 20px;
    }
}

/** XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .logo {
        margin-right: 20px;
    }
}

.debitFooter .externalLink a {
    text-decoration: underline;
    text-align: center;
    font-weight: 900 !important;
}