.loaderOuterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
}

.loaderInnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: column;
}

.loader {
    height: 100px;
    width: 100px;
}

.loaderText {
    font-weight: lighter;
    color:darkgrey;
}